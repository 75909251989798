.page-content {
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.nftHeading h2 {
  color: white;
  font-size: 18px;
  margin: 18px 0;
}

.text_color {
  color: white !important;
  font-size: 18px;
}

.textcolor {
  color: black !important;
  font-size: 15px;
  padding: 16px 0px 0px 0px;
  margin-right: 20px
}

#myProgress {
  width: 100%;
  background-color: #fff0fe;
}

#myBar {
  width: 1%;
  height: 20px;
  background: #000000;
}

.mb_1 {
  margin-bottom: 1rem;
}

.qrcodeimage {
  width: 150px;
  height: 150px;
  margin: auto;
}

.timerdiv {
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 2px 3px rgb(0 245 255);
}

.timerdiv:hover {
  background-color: rgb(0 245 255);
  box-shadow: unset;
}

.timerdiv:hover p {
  color: black !important;
}

.timerdiv p {
  color: white !important;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 1400px) {
  .page-wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

}

.cole {
  border: 1px solid rgb(33 231 134);
  padding: 7px 20px;
  font-size: 18px;
  width: auto;
  display: inline-block;

}
.modal-header h4{
  font-size: 20px;
}
.mre{
  padding: 17px 15px !important;
}

.page-wrapper {
  height: 100%;
  margin-top: 95px;
  margin-bottom: 3rem;
  margin-left: 0px;
}

.popular_nfts {
  position: relative;
}

.popular_nfts .nftTimeItem {
  /* top: 143px; */
  top: 105px;
  left: 48%;
  transform: translate(-50%, -50%);
  text-align: center;
  position: absolute;
  background-color: #141b22;
  /* box-shadow: 0 0 2px 3px rgb(0 245 255); */
  padding: 29px 19px;
  margin: 14px;
  width: 100%;
  /* height: max-content; */
  height: 250px !important;
  border-radius: 4px;
}

.popular_nfts .nftTimeItem h2,
.popular_nfts .nftTimeItem h1 {
  font-size: 18px;
  margin-bottom: 0;
  color: white;
}
.SellersText p{
  font-size: 20px;
}
.popular_nfts .nftTimeItem p {
  font-weight: normal;
  font-size: 16px;
  margin: 0;
  color: white;
}

.popular_nfts .nftTimeItem .TotalEarnChatMain {
  zoom: 90%;
}

.popular_nfts .nftTimeItem .TotalEarnChatMain .priceChart {
  margin: 0 auto;
  position: relative;
  zoom: 95%;
  font-size: 120px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: #000;
}

.popular_nfts .nftTimeItem .TotalEarnChatMain .priceChart span {
  width: 3.33em;
  line-height: 3.33em;
  font-size: 0.3em;
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  display: flex;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  color: rgba(255, 255, 255, 0.7);
  font-family: "sans-serif";
  font-style: normal;
  user-select: none;
  -webkit-touch-callout: none;
}

.popular_nfts .nftTimeItem .TotalEarnChatMain .priceChart::after {
  position: absolute;
  top: 0.08em;
  left: 0.08em;
  display: block;
  content: " ";
  border-radius: 50%;
  background: linear-gradient(153.09deg, #000 -14.22%, #363636 91.92%);
  width: 0.84em;
  height: 0.84em;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}

.popular_nfts .nftTimeItem .TotalEarnChatMain .priceChart .bar {
  position: absolute;
  border-image: linear-gradient(153.09deg,
      #a7eb3d -14.22%,
      rgb(229 184 11) 91.92%);
  border-image-slice: 1;
  width: 117px;
  height: 119px;
  clip: rect(0, 0.8em, 1em, 0);
  border-radius: 50%;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  border: 0.08em solid transparent;
  background: #21e786;

  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.popular_nfts .nftTimeItem button {
  background-color: #000;
  color: #fff;
  border: 1px solid #21e786;
  padding: 2px 12px;
  font-size: 14px;
  width: 100%;
  margin-top: 12px;
}

.mt30hsff {
  margin-top: 240px;
}

.mt30hsff2 {
  margin-top: 15px;
}

.SellersItem {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  padding: 9px;
  transition: all 0.4s ease-in-out;
  background: #141b22;
  position: relative;
}
.newAnimation{
  transition: all 0.4s ease-in-out;
  position: relative;
}
.newAnimation::before{
  top: 0;
    left: 0;
    border-left: 5px solid #21e786;
    border-top: 5px solid #21e786
}
.newAnimation::after{
  bottom: 0;
  right: 0;
  border-right: 5px solid #21e786;
  border-bottom: 5px solid #21e786
}
.newAnimation:after,.newAnimation:before {
  opacity: 0;
  transition: all .3s ease;
  content: "";
  position: absolute;
  width: 50px;
  height: 50px
}
.newAnimation.active,.newAnimation:hover {
  cursor: pointer;
  box-shadow: 0 5px 15px #ffffff4d;
}
.newAnimation.active:after,.newAnimation.active:before,.newAnimation:hover:after,.newAnimation:hover:before {
  opacity: 1
}


.newAnimationcard{
  transition: all 0.4s ease-in-out;
  position: relative;
}
.newAnimationcard::before{
  top: 0;
    left: 0;
    border-left: 5px solid #21e786;
    border-top: 5px solid #21e786
}
.newAnimationcard::after{
  /* bottom: 0; */
  right: 0;
  border-right: 5px solid #21e786;
  border-bottom: 5px solid #21e786
}
.newAnimationcard:after,.newAnimationcard:before {
  opacity: 0;
  transition: all .3s ease;
  content: "";
  position: absolute;
  width: 50px;
  height: 50px
}
.newAnimationcard.active,.newAnimationcard:hover {
  cursor: pointer;
  box-shadow: 0 5px 15px #ffffff4d;
}
.newAnimationcard.active:after,.newAnimationcard.active:before,.newAnimationcard:hover:after,.newAnimationcard:hover:before {
  opacity: 1
}








.SellersItem .SellersText {
  padding-top: 10px;
  width: 100%;
  color: white;
}

.SellersItem .SellersText h2 {
  font-size: 15px;
  margin-bottom: 7px;
  text-align: center;
}

.SellersText .timetext {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.SellersText .timetext span {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SellersText .timetext span img {
  filter: invert(1);
}
.profileSection p{
  font-size: 18px;
}
.Img_profile {
  position: relative;
  top: 85px;
  z-index: 999999;
}

.img_p {
  width: 60px;
  border-radius: 50%;
  box-shadow: 0 0 0px 1px rgb(0 245 255);
}

.zackItem {
  margin-bottom: 11px;
  margin-top: 57px;
}

.zackItem .profileSection {
  color: white;
  border-radius: 5px;
  padding: 46px 15px 68px 15px;
  /* border: 1px solid rgb(0 245 255); */
  text-align: center;
  background-color: #141b22;
}

.zackItem .profileSection h2 {
  font-size: 16px;
}

.binerincome span {
  width: 60px;
  height: 60px;
}

.cardscards {
  overflow-y: auto;
  height: 438px;
}

.maincard {
  border: 2px solid #141b22;
  transition: all 0.3s ease-in-out;
  background: #141b22;
}

.card {
  background-color: transparent;
  box-shadow: unset;
  border: unset;
}

.mycard {
  background-color: rgb(50 54 57) !important;
  border-radius: 10px;
  border: 1px solid #21e786 !important;
}

.popular_nfts {
  position: relative;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
  color: white;
}

.nftteam2nd {
  transform: unset !important;
  text-align: unset !important;
  position: unset !important;
  background-color: unset !important;
  box-shadow: unset !important;
  padding: unset !important;
  margin: unset !important;
  width: unset !important;
  border-radius: unset !important;
}

.card {
  background-color: transparent;
  box-shadow: unset;
  border: unset;
}
.fs-16{
  font-size: 17px;
}
.fs-18{
  font-size: 17px;
}
.modal-header h5{
  font-size: 16px;
}
.popover__wrapper p{
  text-align: center;
  font-size: 16px;
}
.withdraw_btn{
  font-size: 16px;
}
label{
  font-size: 16px;
}
.fontss {
  font-size: 17px;
}
.mycard2 {
  background-color: rgb(50 54 57) !important;
    border-radius: 10px;
    border: 1px solid #21e786 !important;
}

.card {
  background-color: transparent;
  box-shadow: unset;
  border: unset;
}

.myteamcard {
  border: 2px solid #141b22 !important;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  height: 100%;
  overflow-y: auto;
  height: 438px;
  color: white;
  background: #141b22 !important;
}

.myteamcard .card-header {
  border-bottom: 1px dashed #21e786;
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.bgmain {
  background-color: #21e786 !important;
}

.myteamcard .cardteam {
  background-color: #258e37;
  margin-top: 5px;
  color: white;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.myteamcard .cardteam .btn1 {
  background-color: rgb(229 184 11);
  width: 100%;
  height: 100%;
  padding: 17px 10px !important;
  color: white !important;
}

.listcricle .circle-small {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 20px;
}

.listcricle .circle-small .text {
  position: absolute;
  width: 100%;
  top: 26px;
  font-size: 17px;
  text-align: center;
  font-weight: 400;
  line-height: 16px;
  color: white;
}

.listcricle .circle-small svg {
  width: 80px;
  height: 80px;
}

.listcricle .circle-small .bg {
  fill: none;
  stroke-width: 6px;
  stroke: #343035;
}

.listcricle .bg {
  min-height: 100vh;
  padding-bottom: 60px;
  padding-top: 80px;
  /* background: url(../img/back-desktop.5b69385c.jpg) no-repeat 50%; */
  background-size: cover;
  background-attachment: fixed;
}

.myteamcard:hover {
  box-shadow: 0 5px 15px #ffffff4d !important;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.TableMain {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  /* margin-top: 65px; */
  border-collapse: collapse;
  width: 100%;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 800px) {
  .resSeb {
    /* margin-top: 360px; */
    margin-bottom: 20px;
  }

  .reassa {
    height: max-content !important;
  }

}

.bar {
  border: 0 !important;
}

.ksjhajq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 370px) {
  .startedsection .copytext {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 550px) {
  .popular_nfts .nftTimeItem button {
    font-size: 10px;
    width: 47% !important;
  }

  .leffttf {
    margin-right: 5px;
  }

  .mt30hsff {
    margin-top: 305px;
  }

  .popular_nfts .nftTimeItem {
    left: 46%;
  }
}

.fs-14 {
  font-size: 14px;
}

.positioncss {
  position: unset !important;
  transform: unset !important;
  margin: 0 !important;
}

@media (min-width: 992px) and (max-width: 1024px) {
  .page-wrapper {
    margin-top: 150px;
  }

  .zackItem .profileSection {
    padding: 49px 0px 68px 0px !important;
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .zackItem .profileSection {
    padding: 46px 5px 68px 5px !important;
  }

  .cardscards {
    padding-left: 0px !important;
    padding-right: 0px !important;

  }

  .fontss {
    font-size: 14px;
  }

  .listcricle .circle-small {
    width: 70px;
    height: 70px;
  }

  .zackItem .btn_hxn {
    width: 100% !important;
  }

  .popular_nfts .nftTimeItem {
    height: 100% !important;
  }

  .timerdiv p {
    font-size: 20px !important;
    padding: 5px;
  }
}