/* body {
  overflow-x: hidden !important;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.lenk {
  margin-right: 20px;
  color: white;
  padding-top: 0px;
}

.lenk:hover {
  color: white;
}

.navbar-toggler {
  background-color: aliceblue !important;
}

.cardSlid {
  background: #5a3613 !important;
  color: white !important;
  border: none !important;
}



.jashja{
  transition: all 0.4s ease-in-out;
  position: relative;
}
.jashja::before{
  top: 0;
    left: 0;
    border-left: 5px solid #21e786;
    border-top: 5px solid #21e786
}
.jashja::after{
  bottom: 0;
  right: 0;
  border-right: 5px solid #21e786;
  border-bottom: 5px solid #21e786
}
.jashja:after,.jashja:before {
  opacity: 0;
  transition: all .3s ease;
  content: "";
  position: absolute;
  width: 50px;
  height: 50px
}
.jashja.active,.jashja:hover {
  cursor: pointer;
  box-shadow: 0 5px 15px #ffffff4d;
}
.jashja.active:after,.jashja.active:before,.jashja:hover:after,.jashja:hover:before {
  opacity: 1
}
.jashja {
  /* border: 1px solid #21e786 !important; */
  padding: 20px 10px;
  background: #141b22;
}

.jashja:hover {
  /* box-shadow: inset 0px -1px 12px 3px #21e786 !important; */
}

@media screen and (max-width: 850px) {
  .btn-Green {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .jsjdha {
    padding-left: 25px !important;
    padding-right: 25px !important;
    margin-right: 10px;
  }
}

.loginpage .card {
  box-shadow: inset 0px -1px 12px 3px rgb(0 245 255) !important;
  background: #000000ba;
}
