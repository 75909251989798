.Guidepage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
    border-radius: 20px !important;
}

.Guidepage .smallimage {
    width: 400px;
    height: 400px !important;
    overflow: hidden;
}

.Guidepage .smallimage:hover img {
    /* transform: scale(1.1); */
    border-radius: 20px !important;
}
@media (min-width: 320px) and (max-width: 576px) {
    .Guidepage .smallimage {
        width: 320px;
        height: 350px !important;
        overflow: hidden;
    }
}