.Ourteamsection {
    background-color: #030a10;
}
.home3{
    /* z-index: ; */
}
.Ourteamsection .MainHeaaading {
    color: rgb(255 255 255);
    font-weight: 700;
    font-size: 44px;
    margin-bottom: 30px;
    font-family: "Bakbak One";
}

.Ourteamsection .MainHeaaading span {
    color: rgb(33 231 134);
}

.Ourteamsection .card {
    background-image: url(../../../public/assets_front/images/cardbg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    min-height: 250px;
}

.Ourteamsection .card .card-image {
    position: absolute;
    width: 150px;
    top: -80px;
    left: 10px;
    height: 150px;
}

.Ourteamsection .card .card-heading {
    width: 70%;
    margin-left: auto;
    font-size: 20px;
    font-weight: 700;
    color: white;
    margin-top: 7px;
}

.Ourteamsection .card p {
    font-size: 15px;
    color: white;
    padding: 10px 20px;
}

@media (min-width: 320px) and (max-width: 575px) {
    .Ourteamsection .card {
        margin-bottom: 100px;
    }

    .swiper-container {
        overflow: unset;
    }

    .btn-slider {
        flex-direction: column;
        gap: 1rem;
    }

    .Ourteamsection .card .card-image {
        width: 110px;
        top: -60px;
        height: 110px;
    }

    .Ourteamsection .card .card-heading {
        width: 60%;
        font-size: 15px;
    }

    .Ourteamsection .card p {
        font-size: 14px;
    }

    .tf-about-ss {
        z-index: -1;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .Ourteamsection .card {
        margin-bottom: 100px;
    }

    .swiper-container {
        overflow: unset;
    }

    .Ourteamsection .card .card-image {
        width: 110px;
        top: -60px;
        height: 110px;
    }

    .Ourteamsection .card .card-heading {
        width: 60%;
        font-size: 15px;
    }

    .Ourteamsection .card p {
        font-size: 14px;
    }

    .tf-about-ss {
        z-index: -1;
    }
}

@media (min-width: 769px) and (max-width: 991px) {
    .Ourteamsection .card {
        margin-bottom: 100px;
    }

    .swiper-container {
        overflow: unset;
    }

    .tf-about-ss {
        z-index: -1;
    }

    .Ourteamsection .card .card-image {
        width: 110px;
        top: -60px;
        height: 110px;
    }

    .Ourteamsection .card .card-heading {
        width: 60%;
        font-size: 15px;
    }

    .Ourteamsection .card p {
        font-size: 14px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .Ourteamsection .card .card-heading {
        width: 61%;
        margin-left: auto;
        font-size: 16px;
        font-weight: 700;
        color: white;
        margin-top: 6px;
    }

    .Ourteamsection .card p {
        font-size: 14px;
        color: white;
        padding: 10px 20px;
    }

    .Ourteamsection .card .card-image {
        position: absolute;
        width: 130px;
        top: -70px;
        left: 15px;
        height: 130px;
    }
}