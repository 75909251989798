.breadcrumb-title {
  font-size: 15px;
  color: white;
  border-right: 1.5px solid rgba(255, 255, 255, 0.85);
}

.page-breadcrumb .breadcrumb li.breadcrumb-item {
  font-size: 16px;
}

.bx-home-alt:before {
  content: "\ea75";
}

.page-breadcrumb .breadcrumb li.breadcrumb-item {
  font-size: 16px;
}

.breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.modal-dialog {
  margin: 23px auto !important;
  color: white;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: auto;
    margin: 30px auto !important;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-content {
  border-radius: 6px;
  margin: auto;
  padding: 20px 10px;
}

.exchange_width {
  min-height: auto;
}
.modal-content {
  position: relative;
  border: 1px solid #40b34538;
  border-radius: 8px;
  min-height: max-content;
  -webkit-backdrop-filter: blur(10px);
  background-color: rgb(31 31 31);
  backdrop-filter: blur(5px);
  /* box-shadow: inset 0px -1px 12px 3px rgb(0 244 254) !important; */
}
.btn-theme1{
  background:#21e786 !important;
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.box {
  margin: 10px 0;
}

label {
  display: inline-block;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #21e786 !important;
  color: white !important;
}

.form-control::placeholder {
  color: white !important;
}

.form-control {
  color: white !important;
  font-size: 18px;
  line-height: 1.6;
  background-color: #21e786 !important;
  border: 0px solid #fff;
  border-radius: 5px;
  box-shadow: none;
  margin-top: 5px;
}

.btn-Green {
  background-color: #21e786 !important;
  font-size: 16px;
  font-weight: 600;
  color: white;
  border-radius: 0px;
  transition: all 0.3s ease-in-out;
  margin-left: 0.5rem;
}

.btn_hxn {
  background-color: #21e786;
    color: white;
    font-size: 17px;
    border: 1px solid #21e786 !important;
}
.btn_hxn:hover{
  /* background-color: ; */
}
.exchange_width{
  transition: all 0.4s ease-in-out;
  position: relative;
}
.exchange_width::before{
  top: 0;
    left: 0;
    border-left: 5px solid #21e786;
    border-top: 5px solid #21e786
}
.exchange_width::after{
  bottom: 0;
  right: 0;
  border-right: 5px solid #21e786;
  border-bottom: 5px solid #21e786
}
.exchange_width:after,.exchange_width:before {
  opacity: 0;
  transition: all .3s ease;
  content: "";
  position: absolute;
  width: 50px;
  height: 50px
}
.exchange_width.active,.exchange_width:hover {
  cursor: pointer;
  box-shadow: 0 5px 15px #ffffff4d;
}
.exchange_width.active:after,.exchange_width.active:before,.exchange_width:hover:after,.exchange_width:hover:before {
  opacity: 1
}
@media screen and (max-width: 550px) {
  .btn-Green {
    margin-bottom: 10px;
  }
}
