.modal-content-register {
  background: #292c31 !important;
  box-shadow: 0 0 14px rgb(255 255 255 / 50%);
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 20px 20px;
  pointer-events: auto;
  position: relative;
  width: 30rem;
  border-radius: 10px;
  margin-top: 144px;
}
@media (min-width: 320px) and (max-width: 576px) {
  .modal-content-register {
    width: 100%;
  }
}

#myModal input{
  width: 100%;
  height: 40px;
  border-radius: 10px;
  box-shadow: none;
  outline: none;
  border-color: #e5b80b;
  border: 1px solid #e5b80b;
  padding-left: 5px;
  font-size: 15px;
}
.btn_Model {
    padding: 10px 20px;
    border-radius: 2.4rem;
    font-size: 13px;
    display: inline-block;
    cursor: pointer;
    transition: .2s linear;
    color: #fff !important;
    text-decoration: none;
    font: 400 1rem "Mukta Vaani", sans-serif;
    border-width: 0;
    margin-bottom: 0.5rem;
    margin-top: 10px;
    /* width: 100%; */
    max-width: 13rem;
    box-shadow: 2px 3px 2px rgba(166, 165, 165, .16);
    background: #198754;
}